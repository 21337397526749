@import 'theme/light.less';


:root {
  --box-shadow-base: @box-shadow-base;
  --gray-10: @gray-10;
  // ligh css
  --blue-5: #807ee7;
  --theme: default;
  --ant-prefix: ant;
  --html-selector: html;
  --primary-color: #e85a5c;
  --primary-color-2: #e5324b;
  --info-color: #e85a5c;
  --success-color: #52c41a;
  --processing-color: #e85a5c;
  --error-color: #ff5977;
  --highlight-color: #ff4d4f;
  --warning-color: #ffe296;
  --pending-color: #faad14;
  --normal-color: #d9d9d9;
  --sunrise-yellow: #D4B106;
  --sunrise-yellow-hover: #ba9c07;
  --white: #ffffff;
  --black: #000000;
  --font-family: 'Poppins';
  --font-size-base: 16px;
  --font-size-lg: 18px;
  --font-size-sm: 12px;
  --line-height-base: 1.6;
  --border-radius-base: 4px;
  --border-radius-sm: 4px;
  --padding-lg: 24px;
  --padding-md: 16px;
  --padding-sm: 12px;
  --padding-xs: 8px;
  --padding-xss: 4px;
  --margin-lg: 24px;
  --margin-md: 16px;
  --margin-sm: 12px;
  --margin-xs: 8px;
  --margin-xss: 4px;
  --height-base: 32px;
  --height-lg: 40px;
  --height-sm: 24px;
  --link-color: #96b3ff;
  --link-hover-color: #96b3ff;
  --link-active-color: #96b3ff;
  --link-decoration: none;
  --link-hover-decoration: none;
  --link-focus-decoration: none;
  --link-focus-outline: 0;
  --border-color-base: #d7d8db;
  --border-color-split: #f0f0f0;
  --border-color-inverse: #ffffff;
  --border-width-base: 1px;
  --border-style-base: solid;
  --screen-xs: 480px;
  --screen-xs-min: 480px;
  --screen-sm: 576px;
  --screen-sm-min: 576px;
  --screen-md: 768px;
  --screen-md-min: 768px;
  --screen-lg: 992px;
  --screen-lg-min: 992px;
  --screen-xl: 1200px;
  --screen-xl-min: 1200px;
  --screen-xxl: 1600px;
  --screen-xxl-min: 1600px;
  --screen-xs-max: 575px;
  --screen-sm-max: 767px;
  --screen-md-max: 991px;
  --screen-lg-max: 1199px;
  --screen-xl-max: 1599px;
  --layout-header-background: #ffffff;
  --layout-header-height: 84px;
  --text-color: #1e2229;
  --text-color-2: #8692a6;

  --blue-color-1: #096dd9;

  --gray-1: #1e2229;
  --gray-2: #3a404a;
  --gray-3: #545a63;
  --gray-4: #676e79;
  --gray-5: #80868e;
  --gray-6: #d7d8db;
  --gray-7: #d7d8db;
  --gray-8: #fafafa;
  --gray-9: #f7f7f7;
  --gray-10: #191919;
  --gray-11: #464646;
  --gray-12: #999999;
  --gray-13: #d5d9da;
  --gray-14: #FAFAFA;
  --black-85: rgba(0, 0, 0, 0.85);
  --black-45: rgba(0, 0, 0, 0.45);

  --currentColor: 'currentColor';
}

html,
body {
  background-color: @gray-8;
}

.chrome-picker label {
  color: @text-color !important;
}

.ant-table tr.drop-over-downward td {
  background-color: @gray-7;
}

.ant-table tr.drop-over-upward td {
  background-color: @gray-7;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 2px !important;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > tbody
  > tr
  > td {
  padding: 10px;
}
.ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-content
  > table
  > thead
  > tr
  > th {
  padding: 12px;
}
